import React, { useState } from "react";
import axios from "axios";
// import Form from "react-validation/build/form";
import { isEmail } from "validator";
import { useForm } from "react-hook-form";
// import { control } from 'react-validation';
// import CheckButton from "react-validation/build/button";

import './Subscribe.css';

// https://codepen.io/masmerino/pen/EMGvEb
const config = {
  cors: '', // <optional> doesn't display the cors error // https://cors-anywhere.herokuapp.com/ not open any more
  formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfldNtQSGyU8lp7T5ahflfozBBtqybRjfMjTZrinQoV-sSwnA/formResponse'
};
// https://medium.com/@levvi/how-to-use-google-forms-as-a-free-email-service-for-your-custom-react-form-or-any-other-1aa837422a4

const googleforminputs = {
  email: { id: 1995111869, value: '' }
  //phone: { id: 1166974658, value: null },
  //message: { id: 839337160, value: '' }
}

//<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfldNtQSGyU8lp7T5ahflfozBBtqybRjfMjTZrinQoV-sSwnA/viewform?embedded=true" width="640" height="415" frameborder="0" marginheight="0" marginwidth="0">Laden…</iframe>

const validEmail = (value) => {
  if (value && !isEmail(value)) {
    return 'This is not a valid email.';
    // return (
    //   <div className="alert alert-warning" role="alert">
    //     This is not a valid email.
    //   </div>
    // );
  } else {
    return true;
  }
};

const required = (value) => {
  if (!value) {
    return 'This field is required!';
    // return (
    //   <div className="alert alert-danger" role="alert">
    //     This field is required!
    //   </div>
    // );
  } else {
    return true;
  }
};

const Input = ({forwardRegister, error, isChanged, isUsed, hocClassName, labelClassName=null, labeltxt=null, labelfirst=true, ...rest }) => (
  <div className={hocClassName}>
    {(() => {
      if(labeltxt) {
        return (
          <label className={labelClassName}>
            { labelfirst && labeltxt ? labeltxt : ""}
            <input {...forwardRegister} {...rest} />
            { !labelfirst && labeltxt ? labeltxt : ""}
          </label>);
      } else {
        return (<input {...forwardRegister} {...rest} />);
      }
    })()}
    {error && error.type === "warning" && (
      <div className="alert alert-warning" role="alert">{error.message}</div>
    )}
    {error && error.type === "danger" && (
      <div className="alert alert-danger" role="alert">{error.message}</div>
    )}
    {isChanged && isUsed && error}
  </div>
);

// const Input = control(HocInput);

// const Input = ({ name, label, doChange, type='text'}) => {
//   return (
//     <label htmlFor={name} className="form-label">
//       { label }
//       <input type={type} id={name} name={name} onChange={doChange} />
//     </label>
//   )
// }

const Subscribe = (props) => {
  // const form = useRef();
  const { register, handleSubmit, formState: { errors }, } = useForm({mode: "onBlur"}); // "onChange", "onBlur"
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  // useEffect(() => {
  //   console.log(errors);
  // },[errors]);

  const handleRegister = (e) => {
    // console.log(e);
    // console.log(errors);
    //e.preventDefault();

    setMessage("");
    setSuccessful(false);

    // form.current.validateAll();

    let errorlength = Object.keys(errors).length; // checkBtn.current.context._errors.length
    if (errorlength === 0) {
      // email, plainPassword, firstname, familyname, coupon, dayofbirth, commonterms, privacyterms, sharingterms, postalCode, streetname, housenumber, housenumberadditive, expertisearea, bignumber
      var Data = {}
      const formData = new FormData()

      Data['email']=email;
      //console.log(Data);
      Object.keys(Data).forEach((item) => {
        formData.append(`entry.${googleforminputs[item].id}`, Data[item]);
      });

      //console.log(formData);
      //return;

      axios({
            url: `${config.cors}${config.formUrl}`,
            method: 'post',
            data: formData,
            responseType: 'json'
          }).then(
        (response) => {
          //console.log(response);
          if (response.statusText === "" && response.status === 200) {
            setMessage("Successfully subscribed!");
          } else {
            setMessage(response.statusText);
          }
          setSuccessful(true);
        },
        (error) => {
          console.log(error);
          // if ('data' in error.response) {
          //   if ('detail' in error.response.data)
          //   {
          //     // const errorarray = error.response.data.detail.split(/\r?\n/)
          //     // for (let i = 0, l = errorarray.length; i < l; i += 1) {
          //     // }
          //
          //   }
          // }
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            error.message ||
            error.toString();

          if(resMessage==="Network Error") {
            console.log("Bypass");
            // Some CORS error: this site has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
            // BUT, still works on all browsers?
            // Only Chrome doesnt need this bypass.
            setMessage("Subscribed!");
            setSuccessful(true);
          } else {
            setMessage(resMessage);
            setSuccessful(false);
          }
        }
      );

    }
  };
  // <div>
  //   <label htmlFor="email" className="field-label">E-mailadres</label>
  // </div>
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(handleRegister)}>
        {!successful && (
          <div className="formrow">
            <div className="field-group">

              <Input
                forwardRegister={register('email', { validate: { warning: validEmail, danger: required } })}
                type="text"
                hocClassName="inputclass"
                className="inputfield"
                name="email"
                value={email}
                onChange={onChangeEmail}
                error={errors.email}
                placeholder="Enter your email"
              />
            </div>
            <div className="field-group">
                <button className="submit">Subscribe</button>
            </div>
          </div>
        )}

        {message && (
          <div className="field-group">
            <div
              className={ successful ? "alert alert-success" : "alert alert-danger" }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Subscribe;
