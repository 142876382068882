import React, { useEffect } from "react";
import "./Preloader.css";

// https://css-tricks.com/the-shapes-of-css/
// https://bashooka.com/coding/react-loading-spinner-components/

const Preloader = (props) => {

  useEffect(() => {
    function preloader() {
      let preload = document.querySelector(".preloader");
      setTimeout(() => {
        preload.style.opacity = "0";
        setTimeout(() => {
          preload.style.display = "none";
          props.setLoaderCompleted(true);
        }, 1000);
      }, 3000);
    }
    preloader()
  },[props]);

  return (
      <div className="preloader">
        <div className="spinner_wrap">
          <div className="spinner" />
        </div>
      </div>
  );
}

export default Preloader;
