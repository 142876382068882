import React, { useState, useEffect, useRef, useCallback } from "react";

import './TypingTerminal.css';

// https://stackoverflow.com/questions/49886123/trying-to-create-a-typing-effect-on-reactjs
const TypingTerminal = (props) => {
  //console.log("Handling typer");
  const { dataText } = props;

  const [connectionStatus, setConnectionStatus] = useState(props.heading);
  const connectionStatusRef = useRef(connectionStatus);
  connectionStatusRef.current = connectionStatus;
  const [message, setMessage] = useState("");
  const messageRef = useRef(message);
  messageRef.current = message;
  const [isDeleting, setIsDeleting] = useState(false);
  const isDeletingRef = useRef(isDeleting);
  isDeletingRef.current = isDeleting;
  const [cursorVisible, setCursorVisible] = useState(true);
  const cursorVisibleRef = useRef(cursorVisible);
  cursorVisibleRef.current = cursorVisible;
  const [loopNum, setLoopNum] = useState(0);
  const loopNumRef = useRef(loopNum);
  loopNumRef.current = loopNum;
  const [typingSpeed, setTypingSpeed] = useState(150);
  const typingSpeedRef = useRef(typingSpeed);
  typingSpeedRef.current = typingSpeed;
  const hasStarted = useRef(false);


  const handleType = useCallback(
    () => {
    const i = loopNumRef.current % dataText.length;
    const fullText = dataText[i];

    //console.log(messageRef.current.length)
    //console.log(isDeletingRef.current)
    setMessage(isDeletingRef.current ? fullText.substring(0, messageRef.current.length - 1) : fullText.substring(0, messageRef.current.length + 1));
    setTypingSpeed(isDeletingRef.current ? 30 : 150);

    //console.log(loopNum);

    if (!isDeletingRef.current && messageRef.current === fullText) {

      //console.log('FullMessage');
      if (!props.repeat && loopNumRef.current+1 < dataText.length) {
        setTimeout(() => setIsDeleting(true), 500);
      }

    } else if (isDeletingRef.current && messageRef.current === '') {

      //console.log('EmptyMessage');
      setIsDeleting(false);
      setLoopNum(loopNumRef.current + 1);

    }

    //console.log(messageRef.current.substring(messageRef.current.length-2,messageRef.current.length));
    if (messageRef.current.substring(messageRef.current.length-2,messageRef.current.length) === "  ") {
      //console.log('Double Space!');
      setCursorVisible(false);
    } else {
      if(!cursorVisibleRef.current) {
        setCursorVisible(true);
      }
    }

    if(loopNumRef.current===0 && messageRef.current === fullText) {
      setConnectionStatus('connected');
    }

    if(loopNumRef.current===dataText.length-1 && messageRef.current === fullText) {
      setConnectionStatus('disconnected');
    }

    if (!props.repeat && loopNumRef.current < dataText.length) {
      setTimeout(() => { handleType() }, typingSpeedRef.current);
    }
  },[dataText,props.repeat]
  );

  // https://betterprogramming.pub/stop-lying-to-react-about-missing-dependencies-10612e9aeeda
  useEffect(() => {
    if(props.startSign && !hasStarted.current) {
      setTimeout(() => { handleType() }, 500);
      hasStarted.current = true;
    }
  },[props.startSign,hasStarted,handleType]);

  return (
    <div className="TypingTerminal">
      <h1>[{ connectionStatus }]>&nbsp;<br />
        <span>{ message }</span>
        { cursorVisible&&<span id="cursor"/> }
      </h1>
    </div>
  );
};

export default TypingTerminal;
